import React, { useState } from 'react';
import { css } from '@emotion/react';
import Pill from './pill';
import Grid from './grid';

export default function TitleCardsPill({ className, heading, children, pillText, pillLink, columns }) {
  const count = children?.length;

  return (
    <div className={className}>
      <h3
        className="type--underline"
        css={css`
          text-align: center;
          margin-bottom: var(--margin6);

          @media (max-width: 800px) {
            margin-bottom: var(--margin5);
          }

          @media (max-width: 550px) {
            max-width: 17rem;
            margin: 0 auto var(--margin6);
          }
        `}
      >
        {heading}
      </h3>
      <Grid>
        {count > 0 &&
          children.map((child, i) => {
            return (
              <div
                key={i}
                css={css`
                  grid-column-start: span ${(columns) ? 12 / columns : '6'};

                  @media (max-width: 800px) {
                    grid-column-start: span 6;
                  }

                  @media (max-width: 550px) {
                    grid-column-start: span 8;
                  }

                  &:last-of-type:nth-of-type(3n+1):not(:nth-of-type(1)), &:last-of-type:nth-of-type(3n+2):not(:nth-of-type(2)), &:nth-last-of-type(2):nth-of-type(3n+1):not(:nth-of-type(1)) {
                    ${(columns === 3) && `
                      display: none;

                      @media (max-width: 800px) {
                        display: block;
                      }
                    `}
                  }

                  &:last-of-type:nth-of-type(2n+1):not(:nth-of-type(1)) {
                    ${(columns != 3) && `
                      display: none;
                    `}

                    ${(columns === 3) && `
                      @media (max-width: 800px) {
                        display: none;
                      }
                    `}

                    @media (max-width: 550px) {
                      display: block;
                    }
                  }
                `}
              >
                {child}
              </div>
            );
          })}
      </Grid>
      <div
        css={css`
          text-align: center;
          margin-top: var(--margin8);

          @media (max-width: 800px) {
            margin-top: var(--gutter);
          }

          @media (max-width: 550px) {
            margin-top: var(--margin5);
          }
        `}
      >
        <Pill size={3} url={pillLink} text={pillText} hover={true} transparent={true} />
      </div>
    </div>
  );
}
