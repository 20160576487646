import React, { useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import Pill from './pill';
import BlockContent from './block-content';

export default function ImageLink({ text, url, image, isPopup, popupText }) {
  const [displayPopup, setDisplayPopup] = useState(false);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        border: ${isPopup ? '4px solid var(--black)' : 'none'};
        filter: ${isPopup ? 'var(--dropShadow)' : 'none'};
      `}
    >
      {image && (
        <SanityImage
          {...image}
          width={isPopup ? 660 : 1980}
          loading="eager"
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: '0',
          }}
        />
      )}
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        `}
      >
        <Pill
          size={isPopup ? 2 : 4}
          text={text}
          url={url}
          hover={true}
          background={true}
          onMouseEnter={() => setDisplayPopup(true)}
          onMouseOut={() => setDisplayPopup(false)}
        />
      </div>
      {popupText && (
        <div
          css={css`
            position: absolute;
            bottom: var(--gutter);
            left: var(--gutter);
            display: ${displayPopup ? 'block' : 'none'};
            width: 28rem;
            color: var(--white);
            background-color: var(--black);
            padding: calc(var(--gutter) * 0.4) var(--gutterHalf);
            filter: var(--dropShadow);

            @media (max-width: 800px) {
              display: none;
            }
          `}
        >
          <BlockContent content={popupText} />
        </div>
      )}
    </div>
  );
}
