import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function TenetList({ className, children, refs }) {
  refs.current = [];

  return (
    <div
      className={className}
      css={css`
        text-align: center;
      `}
    >
      {children.length > 0 &&
        children.map((child, i) => (
          <div
            key={i}
            ref={(el) => {
              if (el !== null) refs.current.push(el);
            }}
            css={css`
              margin-bottom: 25rem;

              @media (max-width: 800px) {
                margin-bottom: 20rem;
              }

              @media (max-width: 550px) {
                margin-bottom: 15rem;
              }
            `}
          >
            {child}
          </div>
        ))}
    </div>
  );
}
